import * as React from "react";
import {
    Button,
    Form,
    Header,
    Loader,
    Message,
    Modal,
    Popup,
    Table,
} from "semantic-ui-react";
import {PaginatedTable} from "../../components/paginatedTable";
import {
    ApiResult,
    PageResult,
    SortingConfig,
    AgencyGroup,
    AgencyGroupTypeEnum,
    MembershipStatus,
    MembershipStatusFilterEnum,
    MembershipStatusSortingOptions,
    ResponseOptionTypeEnum,
} from "@bryxinc/lunch/models";

import {RespondOptionSelectItem} from "../members/respondToJoinRequestModal";
import {SelectPermissions} from "../members/selectPermissions";
import {SelectResponseOptions} from "../members/selectResponseOptions";

import {RouteComponentProps} from "react-router";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

export type ViewMembersModalViewStatus =
    | { key: "hidden" }
    | { key: "shown"; group: AgencyGroup };

interface ViewMembersModalProps
    extends RouteComponentProps<any>,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    agencyId: string;
    viewStatus: ViewMembersModalViewStatus;

    onDismiss(reload: boolean): void;
}

interface ViewMembersModalState {
    filter: MembershipStatusFilterEnum;
    canSend: boolean;
    canRespond: boolean;
    actionStatus:
        | { key: "loading" }
        | { key: "ready" }
        | { key: "error"; message: string };
    currentActionClientId: string | null;
    status:
        | { key: "loading" }
        | { key: "ready"; responseOptions: RespondOptionSelectItem[] }
        | { key: "error"; message: string };
}

export class ViewMembersModal extends React.Component<ViewMembersModalProps,
    ViewMembersModalState> {
    private paginatedTable: PaginatedTable | null = null;

    constructor(props: ViewMembersModalProps, context: any) {
        super(props, context);

        this.state = ViewMembersModal.getInitialState();
    }

    static getInitialState(): ViewMembersModalState {
        return {
            filter: MembershipStatusFilterEnum.all,
            canSend: false,
            canRespond: false,
            actionStatus: {key: "ready"},
            currentActionClientId: null,
            status: {key: "loading"},
        };
    }

    private loadResponseOptions() {
        this.props.api.getAgencyResponseOptions(
            this.props.agencyId,
            null,
            [ResponseOptionTypeEnum.positive, ResponseOptionTypeEnum.negative],
            (result) => {
                if (result.success == true) {
                    this.setState({
                        status: {
                            key: "ready",
                            responseOptions: SelectResponseOptions.getWrappedResponseOptions(
                                result.value,
                            ),
                        },
                    });
                } else {
                    this.props.local.logWarn(
                        `Failed to load agency's response options: ${result.debugMessage}`,
                    );
                    this.setState({
                        status: {key: "error", message: result.message},
                    });
                }
            },
        );
    }

    componentWillReceiveProps(newProps: ViewMembersModalProps) {
        if (
            this.props.viewStatus.key == "hidden" &&
            newProps.viewStatus.key == "shown"
        ) {
            // Opening modal
            this.setState(ViewMembersModal.getInitialState(), () => {
                this.loadResponseOptions();
            });
        }
    }

    private loadItems(
        limit: number,
        activePage: number,
        searchString: string | null,
        sortConfig: SortingConfig<MembershipStatusSortingOptions>,
        callback: (result: ApiResult<PageResult<MembershipStatus>>) => void,
    ) {
        const {viewStatus} = this.props;

        if (viewStatus.key == "shown") {
            this.props.api.getGroupMembers(
                this.props.agencyId,
                viewStatus.group.id,
                this.state.filter,
                limit,
                activePage,
                searchString,
                sortConfig,
                callback,
            );
        }
    }

    private changeFilter(filterEnum: MembershipStatusFilterEnum) {
        this.setState(
            {
                filter: filterEnum,
            },
            () => {
                if (this.paginatedTable != null) {
                    this.paginatedTable.resetTable();
                }
            },
        );
    }

    private toggleResponseOption(option: RespondOptionSelectItem) {
        this.setState((prevState) => {
            if (prevState.status.key == "ready") {
                prevState.status.responseOptions.forEach((o) => {
                    if (o.responseOption.id == option.responseOption.id) {
                        // Found response option
                        o.selected = !o.selected;
                    }
                });
            }
            return prevState;
        });
    }

    private onActionClick(clientId: string) {
        this.setState((prevState: ViewMembersModalState) => {
            if (prevState.status.key == "ready") {
                const options = prevState.status.responseOptions.map(
                    (r) => r.responseOption,
                );
                prevState.status.responseOptions =
                    SelectResponseOptions.getWrappedResponseOptions(options);
            }
            prevState.actionStatus = {key: "ready"};
            prevState.currentActionClientId = clientId;
            prevState.canRespond = false;
            prevState.canSend = false;
            return prevState;
        });
    }

    private canAdd(responseOptions: RespondOptionSelectItem[]) {
        if (
            this.props.viewStatus.key == "shown" &&
            this.props.viewStatus.group.type == AgencyGroupTypeEnum.messaging
        ) {
            return true;
        }

        const atLeastOneNegative =
            responseOptions.filter(
                (o) =>
                    o.responseOption.type == ResponseOptionTypeEnum.negative &&
                    o.selected == true,
            ).length > 0;
        const atLeastOnePositive =
            responseOptions.filter(
                (o) =>
                    o.responseOption.type == ResponseOptionTypeEnum.positive &&
                    o.selected == true,
            ).length > 0;

        return atLeastOneNegative && atLeastOnePositive;
    }

    private addMember(clientId: string) {
        const {agencyId, viewStatus} = this.props;
        const {canSend, canRespond, status} = this.state;
        if (viewStatus.key == "shown" && status.key == "ready") {
            const selectedResponseOptions = status.responseOptions
                .filter((r) => r.selected)
                .map((r) => r.responseOption);
            this.setState({
                actionStatus: {key: "loading"},
            });

            if (viewStatus.group.type == AgencyGroupTypeEnum.dispatch) {
                this.props.api.addClientToDispatchGroup(
                    agencyId,
                    viewStatus.group.id,
                    clientId,
                    selectedResponseOptions,
                    canSend,
                    canRespond,
                    (result) => {
                        if (result.success == true) {
                            this.setState(
                                {
                                    actionStatus: {key: "ready"},
                                    currentActionClientId: null,
                                },
                                () => {
                                    if (this.paginatedTable != null) {
                                        this.paginatedTable.reload();
                                    }
                                },
                            );
                        } else {
                            this.props.local.logWarn(
                                `Failed to add member to dispatch group: ${result.debugMessage}`,
                            );
                            this.setState({
                                actionStatus: {key: "error", message: result.message},
                            });
                        }
                    },
                );
            } else {
                this.props.api.addClientToMessagingGroup(
                    agencyId,
                    viewStatus.group.id,
                    clientId,
                    canSend,
                    (result) => {
                        if (result.success == true) {
                            this.setState(
                                {
                                    actionStatus: {key: "ready"},
                                    currentActionClientId: null,
                                },
                                () => {
                                    if (this.paginatedTable != null) {
                                        this.paginatedTable.reload();
                                    }
                                },
                            );
                        } else {
                            this.props.local.logWarn(
                                `Failed to add member to dispatch group: ${result.debugMessage}`,
                            );
                            this.setState({
                                actionStatus: {key: "error", message: result.message},
                            });
                        }
                    },
                );
            }
        }
    }

    private removeMember(clientId: string) {
        const {agencyId, viewStatus} = this.props;
        const {status} = this.state;

        if (viewStatus.key == "shown" && status.key == "ready") {
            this.setState({
                actionStatus: {key: "loading"},
            });

            this.props.api.removeClientFromGroup(
                agencyId,
                viewStatus.group.id,
                clientId,
                (result) => {
                    if (result.success == true) {
                        this.setState(
                            {
                                actionStatus: {key: "ready"},
                                currentActionClientId: null,
                            },
                            () => {
                                if (this.paginatedTable != null) {
                                    this.paginatedTable.reload();
                                }
                            },
                        );
                    } else {
                        this.props.local.logWarn(
                            `Failed to add member: ${result.debugMessage}`,
                        );
                        this.setState({
                            actionStatus: {key: "error", message: result.message},
                        });
                    }
                },
            );
        }
    }

    private closeAction() {
        this.setState({
            currentActionClientId: null,
        });
    }

    render() {
        const {onDismiss, viewStatus} = this.props;
        const {canSend, canRespond, status} = this.state;

        let content;
        if (status.key == "loading") {
            content = <Loader className="dark"/>;
        } else if (status.key == "error") {
            // handle error
            content = <Message negative content={status.message}/>;
        } else {
            content = (
                <PaginatedTable
                    {...this.props}
                    uniqueKey="groups.viewMembers"
                    className="underHorizNavContent tableUnderNavContent"
                    ref={(r) => (this.paginatedTable = r)}
                    style={{paddingBottom: "30px"}}
                    sortable
                    selectable={false}
                    rightItem={
                        <Popup
                            on="click"
                            position="bottom right"
                            trigger={
                                <Button
                                    icon="filter"
                                    color={
                                        this.state.filter != MembershipStatusFilterEnum.all
                                            ? "orange"
                                            : undefined
                                    }
                                />
                            }
                            header={this.props.t("groups.viewMembers.filter.header")}
                            style={{maxWidth: "none"}}
                            content={
                                <div style={{padding: "30px 10px 15px 10px"}}>
                                    <Button.Group>
                                        <Button
                                            toggle
                                            style={{width: "135px"}}
                                            active={
                                                this.state.filter == MembershipStatusFilterEnum.all
                                            }
                                            onClick={() =>
                                                this.changeFilter(MembershipStatusFilterEnum.all)
                                            }
                                            content={this.props.t("groups.viewMembers.filter.all")}
                                        />
                                        <Button
                                            toggle
                                            style={{width: "135px"}}
                                            active={
                                                this.state.filter == MembershipStatusFilterEnum.inGroup
                                            }
                                            onClick={() =>
                                                this.changeFilter(MembershipStatusFilterEnum.inGroup)
                                            }
                                            content={this.props.t(
                                                "groups.viewMembers.filter.inGroup",
                                            )}
                                        />
                                        <Button
                                            toggle
                                            style={{width: "135px"}}
                                            active={
                                                this.state.filter ==
                                                MembershipStatusFilterEnum.notInGroup
                                            }
                                            onClick={() =>
                                                this.changeFilter(MembershipStatusFilterEnum.notInGroup)
                                            }
                                            content={this.props.t(
                                                "groups.viewMembers.filter.notInGroup",
                                            )}
                                        />
                                    </Button.Group>
                                </div>
                            }
                        />
                    }
                    loader={{
                        loadId: this.props.agencyId,
                        loadItems: this.loadItems.bind(this),
                    }}
                    headerDataItems={[
                        {
                            i18nKey: "groups.viewMembers.name",
                            headerKey: "name",
                            width: 16,
                        },
                    ]}
                    renderItem={(item: any) => {
                        const membership = item as MembershipStatus;
                        const actionButton =
                            membership.hasGroup != true ? (
                                <Popup
                                    position="left center"
                                    open={this.state.currentActionClientId == membership.id}
                                    header={
                                        <Header as="h1">
                                            {this.props.t("groups.viewMembers.addHeader")}
                                        </Header>
                                    }
                                    style={{padding: "20px", maxWidth: "none"}}
                                    trigger={
                                        <Button
                                            positive
                                            compact
                                            style={{width: "100px"}}
                                            onClick={() => this.onActionClick(membership.id)}
                                            content={this.props.t("groups.viewMembers.add")}
                                        />
                                    }
                                    content={
                                        <div>
                                            <Form style={{minWidth: "400px"}}>
                                                {viewStatus.key == "shown" &&
                                                viewStatus.group.type ==
                                                AgencyGroupTypeEnum.dispatch ? (
                                                    <SelectResponseOptions
                                                        {...this.props}
                                                        style={{marginBottom: "10px", marginTop: "20px"}}
                                                        responseOptions={status.responseOptions}
                                                        onSelect={(option) =>
                                                            this.toggleResponseOption(option)
                                                        }
                                                    />
                                                ) : undefined}
                                                <SelectPermissions
                                                    {...this.props}
                                                    style={{marginBottom: 0}}
                                                    canSend={canSend}
                                                    canRespond={
                                                        viewStatus.key == "shown" &&
                                                        viewStatus.group.type ==
                                                        AgencyGroupTypeEnum.dispatch
                                                            ? canRespond
                                                            : undefined
                                                    }
                                                    onPermissionClick={(t, v) => {
                                                        if (t == "send") {
                                                            this.setState({
                                                                canSend: v,
                                                            });
                                                        } else {
                                                            this.setState({
                                                                canRespond: v,
                                                            });
                                                        }
                                                    }}
                                                />
                                                <div
                                                    style={{
                                                        display: "flex",
                                                        justifyContent: "flex-end",
                                                        alignItems: "center",
                                                    }}
                                                >
                                                    <Button
                                                        content={this.props.t("general.cancel")}
                                                        onClick={() => this.closeAction()}
                                                        style={{
                                                            marginTop: "20px",
                                                            marginLeft: "5px",
                                                            width: "85px",
                                                        }}
                                                    />
                                                    <Button
                                                        positive
                                                        content={this.props.t("groups.viewMembers.add")}
                                                        loading={this.state.actionStatus.key == "loading"}
                                                        disabled={!this.canAdd(status.responseOptions)}
                                                        onClick={() => this.addMember(membership.id)}
                                                        style={{
                                                            marginTop: "20px",
                                                            marginRight: "5px",
                                                            width: "85px",
                                                        }}
                                                    />
                                                </div>
                                            </Form>
                                            {this.state.actionStatus.key == "error" ? (
                                                <Message
                                                    error
                                                    content={this.state.actionStatus.message}
                                                />
                                            ) : undefined}
                                        </div>
                                    }
                                />
                            ) : (
                                <Popup
                                    position="left center"
                                    open={this.state.currentActionClientId == membership.id}
                                    header={
                                        <Header as="h1">
                                            {this.props.t("groups.viewMembers.removeHeader")}
                                        </Header>
                                    }
                                    style={{padding: "20px", maxWidth: "none"}}
                                    trigger={
                                        <Button
                                            negative
                                            compact
                                            style={{width: "100px"}}
                                            onClick={() => this.onActionClick(membership.id)}
                                            content={this.props.t("groups.viewMembers.remove")}
                                        />
                                    }
                                    content={
                                        <div style={{minWidth: "400px"}}>
                                            <div>
                                                {this.props.t("groups.viewMembers.removeExplain", {
                                                    replace: {
                                                        memberName: membership.clientName,
                                                        groupName:
                                                            viewStatus.key == "shown"
                                                                ? viewStatus.group.name
                                                                : this.props.t(
                                                                "groups.viewMembers.removeGroupDefault",
                                                                ),
                                                    },
                                                })}
                                            </div>
                                            <div
                                                style={{
                                                    display: "flex",
                                                    justifyContent: "flex-end",
                                                    alignItems: "center",
                                                }}
                                            >
                                                <Button
                                                    compact
                                                    content={this.props.t("general.cancel")}
                                                    onClick={() => this.closeAction()}
                                                    style={{marginTop: "20px", marginLeft: "5px"}}
                                                />
                                                <Button
                                                    negative
                                                    compact
                                                    loading={this.state.actionStatus.key == "loading"}
                                                    onClick={() => this.removeMember(membership.id)}
                                                    content={this.props.t("groups.viewMembers.remove")}
                                                    style={{marginTop: "20px", marginRight: "5px"}}
                                                />
                                            </div>
                                            {this.state.actionStatus.key == "error" ? (
                                                <Message
                                                    error
                                                    content={this.state.actionStatus.message}
                                                />
                                            ) : undefined}
                                        </div>
                                    }
                                />
                            );

                        return (
                            <Table.Row key={membership.id}>
                                <Table.Cell
                                    style={{
                                        display: "flex",
                                        alignItems: "center",
                                        justifyContent: "space-between",
                                    }}
                                >
                                    {membership.clientName}
                                    <div style={{float: "right"}}>{actionButton}</div>
                                </Table.Cell>
                            </Table.Row>
                        );
                    }}
                    defaultSorting={{
                        column: "name",
                        direction: "asc",
                    }}
                />
            );
        }

        return (
            <Modal
                size="large"
                open={viewStatus.key == "shown"}
                onClose={() => onDismiss(true)}
            >
                <Modal.Header>
                    {this.props.t("groups.viewMembers.header", {
                        replace: {
                            groupName: viewStatus.key == "shown" ? viewStatus.group.name : "",
                        },
                    })}
                </Modal.Header>
                <Modal.Content
                    style={{
                        backgroundColor: "#fbfbfb",
                        height: "700px",
                        padding: "0 10px 10px 10px",
                    }}
                >
                    {content}
                </Modal.Content>
            </Modal>
        );
    }
}

export default withContext(ViewMembersModal, "api", "local", "i18n");
