import {Point} from "geojson";
import {latLng, geoJSON} from 'leaflet';
import * as React from 'react';
import {RouteComponentProps} from "react-router";
import {Button, Dimmer, Form, Grid, Loader, Message, Modal} from "semantic-ui-react";
import {BryxGeoJSONLayer} from "../../components/bryxGeoJSONLayer";
import {BryxMap} from "../../components/bryxMap";
import {AgencyRegion, AuthAgency, Station, Geocode} from '@bryxinc/lunch/models';
import BryxApi from '@bryxinc/lunch/utils/ManagementApi';
import {nullIfBlank, NumberStatus, parseNumberStatus} from '@bryxinc/lunch/utils/functions';
import {withContext, WithTranslation, WithLocal, WithApi} from '@bryxinc/lunch/context';


export type CreateEditStationModalViewStatus =
    { key: "hidden" }
    | { key: "create" }
    | { key: "edit", station: Station };

interface CreateEditStationModalProps extends RouteComponentProps<any>, WithTranslation, WithLocal, WithApi<BryxApi> {
    viewStatus: CreateEditStationModalViewStatus;
    selectedAgency: AuthAgency;
    onClose: () => void;
    onComplete: (updatedStation: Station) => void;
}

interface CreateEditStationModalState {
    actionStatus: { key: "ready" } | { key: "loading" } | { key: "error", message: string };

    // Form Fill
    name: string | null;
    customAddress: string | null;
    latitudeString: string | null;
    longitudeString: string | null;

    // Geocoding / Search
    street: string | null;
    city: string | null;
    state: string | null;

    // See createJobTab for more info on loadId
    geocodeStatus: { key: "ready" } | { key: "loading", loadId: string } | { key: "success", geocodeLocation: Geocode } | { key: "error", message: string };
    agencyRegionStatus: { key: "loading" } | { key: "success", region: AgencyRegion } | { key: "error", message: string };
}

type LocationStatus = { key: "incomplete" } | { key: "ok", latitude: number, longitude: number };

type SubmissionStatus =
    {
        key: "incomplete",
    } |
    {
        key: "createOk",
        name: string,
        address: string,
        latitude: number,
        longitude: number,
    } |
    {
        key: "editOk",
        name: string | null,
        address: string | null,
        latitude: number | null,
        longitude: number | null,
    };

export class CreateEditStationModal extends React.Component<CreateEditStationModalProps, CreateEditStationModalState> {
    constructor(props: CreateEditStationModalProps, context: any) {
        super(props, context);
        this.state = CreateEditStationModal.getDefaultState(props);
    }

    componentDidMount() {
        if (this.props.viewStatus.key != "hidden") {
            this.loadRequirements();
        }
    }

    componentWillReceiveProps(nextProps: CreateEditStationModalProps) {
        if (this.props.viewStatus.key == "hidden" && nextProps.viewStatus.key != "hidden") {
            this.setState(CreateEditStationModal.getDefaultState(nextProps), () => this.loadRequirements());
        }
    }

    private loadRequirements() {
        this.setState({agencyRegionStatus: {key: "loading"}});
        this.props.api.getAgencyRegion(this.props.selectedAgency.id, result => {
            if (result.success == true) {
                this.setState((prevState: CreateEditStationModalState) => {
                    prevState.agencyRegionStatus = {key: "success", region: result.value};
                    prevState.city = prevState.city || result.value.city;
                    prevState.state = prevState.state || result.value.state;
                    return prevState;
                });
            } else {
                this.props.local.logWarn(`Error getting agency region: ${this.props.selectedAgency.id}: ${result.debugMessage}`);
                this.setState({agencyRegionStatus: {key: "error", message: result.message}});
            }
        });
    }

    private static getDefaultState(props: CreateEditStationModalProps): CreateEditStationModalState {
        if (props.viewStatus.key == "hidden" || props.viewStatus.key == "create") {
            return {
                actionStatus: {key: "ready"},
                name: null,
                customAddress: null,
                latitudeString: null,
                longitudeString: null,
                street: null,
                city: null,
                state: null,
                geocodeStatus: {key: "ready"},
                agencyRegionStatus: {key: "loading"},
            };
        } else {
            const {station} = props.viewStatus;
            return {
                actionStatus: {key: "ready"},
                name: station.name,
                customAddress: station.address,
                latitudeString: station.latitude.toString(),
                longitudeString: station.longitude.toString(),
                street: null,
                city: null,
                state: null,
                geocodeStatus: {key: "ready"},
                agencyRegionStatus: {key: "loading"},
            };
        }
    }

    private getComputedAddress(): string | null {
        const {customAddress, street, city, state} = this.state;
        if (customAddress != null) {
            return customAddress;
        } else if (street != null && city != null && state != null) {
            return `${street}, ${city}, ${state}`;
        } else {
            return null;
        }
    }

    private getLatitudeStatus(): NumberStatus {
        return parseNumberStatus(this.state.latitudeString);
    }

    private getLongitudeStatus(): NumberStatus {
        return parseNumberStatus(this.state.longitudeString);
    }

    private getLocationStatus(): LocationStatus {
        const latitudeStatus = this.getLatitudeStatus();
        const longitudeStatus = this.getLongitudeStatus();
        return latitudeStatus.key == "ok" && longitudeStatus.key == "ok" ? {
            key: "ok",
            latitude: latitudeStatus.value,
            longitude: longitudeStatus.value,
        } : {key: "incomplete"};
    }

    private getSubmissionStatus(): SubmissionStatus {
        const {name, geocodeStatus} = this.state;
        const {viewStatus} = this.props;
        const address = this.getComputedAddress();
        const latitudeStatus = this.getLatitudeStatus();
        const longitudeStatus = this.getLongitudeStatus();
        if (viewStatus.key == "hidden" || name == null || address == null || address == "" || latitudeStatus.key != "ok" || longitudeStatus.key != "ok" || geocodeStatus.key == "loading") {
            return {key: "incomplete"};
        } else if (viewStatus.key == "create") {
            return {
                key: "createOk",
                name: name,
                address: address,
                latitude: latitudeStatus.value,
                longitude: longitudeStatus.value,
            };
        } else {
            const {station} = viewStatus;
            const setName = station.name != name ? name : null;
            const setAddress = station.address != address ? address : null;
            const setLatitude = station.latitude != latitudeStatus.value ? latitudeStatus.value : null;
            const setLongitude = station.longitude != longitudeStatus.value ? longitudeStatus.value : null;

            const isDirty = setName != null || setAddress != null || setLatitude != null || setLongitude != null;
            return isDirty ? {
                key: "editOk",
                name: setName,
                address: setAddress,
                latitude: setLatitude,
                longitude: setLongitude,
            } : {key: "incomplete"};
        }
    }

    private onCreate() {
        const submissionStatus = this.getSubmissionStatus();
        const {viewStatus} = this.props;
        if (viewStatus.key != "create" || submissionStatus.key != "createOk") {
            return;
        }
        const {name, address, latitude, longitude} = submissionStatus;
        this.setState({actionStatus: {key: "loading"}});
        this.props.api.createStation(this.props.selectedAgency.id,
            name,
            address,
            latitude,
            longitude,
            result => {
                if (result.success == true) {
                    this.props.onComplete(result.value);
                } else {
                    this.props.local.logWarn(`Failed to create station in agency: ${result.debugMessage || result.message}`);
                    this.setState({actionStatus: {key: "error", message: result.message}});
                }
            });
    }

    private onEdit() {
        const submissionStatus = this.getSubmissionStatus();
        const {viewStatus} = this.props;
        if (viewStatus.key != "edit" || submissionStatus.key != "editOk") {
            return;
        }
        const {name, address, latitude, longitude} = submissionStatus;
        this.setState({actionStatus: {key: "loading"}});
        this.props.api.editStation(this.props.selectedAgency.id,
            viewStatus.station.id,
            name,
            address,
            latitude,
            longitude,
            result => {
                if (result.success == true) {
                    this.props.onComplete(result.value);
                } else {
                    this.props.local.logWarn(`Failed to edit station in agency: ${result.debugMessage || result.message}`);
                    this.setState({actionStatus: {key: "error", message: result.message}});
                }
            });
    }

    private onGeocode() {
        const {selectedAgency} = this.props;
        const {street, city, state} = this.state;
        if (street == null || city == null || state == null) {
            return;
        }
        const loadId = new Date().getTime().toString();
        this.setState({geocodeStatus: {key: "loading", loadId: loadId}});
        this.props.api.geocode(
            selectedAgency.id,
            street.replace(/^\s+|\s+$/g, ""),
            city.replace(/^\s+|\s+$/g, ""),
            state.replace(/^\s+|\s+$/g, ""),
            result => {
                this.setState((prevState: CreateEditStationModalState) => {
                    if (prevState.geocodeStatus.key != "loading" || prevState.geocodeStatus.loadId != loadId) {
                        // Don't set any values if the user aborted the request
                        return prevState;
                    }
                    if (result.success == true) {
                        prevState.geocodeStatus = {
                            key: "success",
                            geocodeLocation: result.value,
                        };
                        prevState.latitudeString = result.value.centroid.coordinates[1].toString();
                        prevState.longitudeString = result.value.centroid.coordinates[0].toString();
                    } else {
                        // State is set but unused, user can choose to enter a different address, type or select the coordinates, or submit without a location
                        this.props.local.logWarn(`Failed to geocode location: ${result.debugMessage || result.message}`);
                        prevState.geocodeStatus = {key: "error", message: result.message};
                    }
                    return prevState;
                });
            });
    }

    private renderContent(): React.ReactNode {
        const {
            agencyRegionStatus,
            actionStatus,
            geocodeStatus,
            latitudeString,
            longitudeString,
            name,
            street,
            city,
            state
        } = this.state;
        let actionPanel = null;
        if (actionStatus.key == "error") {
            actionPanel = (
                <Message negative
                         header={(
                             this.props.viewStatus.key == "create" ?
                                 this.props.t("agency.general.createEditStation.failedToCreate") :
                                 this.props.t("agency.general.createEditStation.failedToEdit")
                         )}
                         content={actionStatus.message}/>
            );
        }

        const locationStatus = this.getLocationStatus();

        let dimmerContent = null;
        if (geocodeStatus.key == "loading") {
            dimmerContent = (
                <Loader active>
                    <Button size="tiny"
                            color="grey"
                            compact
                            content={this.props.t("agency.general.createEditStation.cancelGeocode")}
                            onClick={() => this.setState({geocodeStatus: {key: "ready"}})}/>
                </Loader>
            );
        } else if (agencyRegionStatus.key == "loading") {
            dimmerContent = <Loader active/>;
        }

        let bounds;
        if (locationStatus.key == "ok") {
            bounds = latLng([locationStatus.latitude, locationStatus.longitude]).toBounds(300);
        } else if (agencyRegionStatus.key == "success") {
            bounds = geoJSON(agencyRegionStatus.region.bufferedBoundary).getBounds();
        }

        return (
            <div>
                <Grid>
                    <Grid.Row>
                        <Grid.Column width={8} style={{display: "flex", flexDirection: "column"}}>
                            <Form onSubmit={e => {
                                e.preventDefault();
                                this.onGeocode();
                            }}>
                                <Form.Group widths="equal">
                                    <Form.Input type="text"
                                                autoFocus={this.props.viewStatus.key == "create"}
                                                label={this.props.t("agency.general.createEditStation.street")}
                                                placeholder={this.props.t("agency.general.createEditStation.street")}
                                                value={street || ""}
                                                onChange={(e, d) => this.setState({street: nullIfBlank(d.value)})}/>
                                </Form.Group>
                                <Form.Group>
                                    <Form.Input type="text"
                                                width={9}
                                                label={this.props.t("agency.general.createEditStation.city")}
                                                placeholder={this.props.t("agency.general.createEditStation.city")}
                                                value={city || ""}
                                                onChange={(e, d) => this.setState({city: nullIfBlank(d.value)})}/>
                                    <Form.Input type="text"
                                                width={5}
                                                label={this.props.t("agency.general.createEditStation.state")}
                                                placeholder={this.props.t("agency.general.createEditStation.state")}
                                                value={state || ""}
                                                onChange={(e, d) => this.setState({state: nullIfBlank(d.value)})}/>
                                    <Form.Field width={2}>
                                        <label>&nbsp;</label>
                                        <Button primary
                                                icon="search"
                                                disabled={street == null || city == null || state == null}
                                                onClick={() => this.onGeocode()}/>
                                    </Form.Field>
                                </Form.Group>
                            </Form>
                            <Dimmer.Dimmable blurring dimmed={dimmerContent != null} style={{display: "flex", flex: 1}}>
                                <Dimmer active={dimmerContent != null} inverted>
                                    {dimmerContent}
                                </Dimmer>
                                <BryxMap {...this.props}
                                         bounds={bounds}
                                         style={{height: "250px"}}
                                         onclick={e => this.setState({
                                             latitudeString: e.latlng.lat.toString(),
                                             longitudeString: e.latlng.lng.toString(),
                                         })}>
                                    {locationStatus.key == "ok" ? <BryxGeoJSONLayer {...this.props} geojson={{
                                        type: "Point",
                                        coordinates: [locationStatus.longitude, locationStatus.latitude]
                                    } as Point}/> : null}
                                </BryxMap>
                            </Dimmer.Dimmable>
                        </Grid.Column>
                        <Grid.Column width={8}>
                            <Form>
                                <Message info
                                         list={[
                                             this.props.t("agency.general.createEditStation.formHelp1"),
                                             this.props.t("agency.general.createEditStation.formHelp2"),
                                             this.props.t("agency.general.createEditStation.formHelp3"),
                                         ]}/>
                                <Form.Group widths="equal">
                                    <Form.Input type="text"
                                                required
                                                label={this.props.t("agency.general.createEditStation.name")}
                                                placeholder={this.props.t("agency.general.createEditStation.name")}
                                                value={name || ""}
                                                onChange={(e, d) => this.setState({name: nullIfBlank(d.value)})}/>
                                </Form.Group>
                                <Form.Group widths="equal">
                                    <Form.Input type="text"
                                                required
                                                label={this.props.t("agency.general.createEditStation.address")}
                                                placeholder={this.props.t("agency.general.createEditStation.address")}
                                                value={this.getComputedAddress() || ""}
                                                onChange={(e, d) => this.setState({customAddress: d.value || ""})}/>
                                </Form.Group>
                                <Form.Group widths="equal">
                                    <Form.Input type="text"
                                                required
                                                label={this.props.t("agency.general.createEditStation.latitude")}
                                                placeholder={this.props.t("agency.general.createEditStation.latitude")}
                                                value={latitudeString || ""}
                                                error={this.getLatitudeStatus().key == "error"}
                                                disabled={geocodeStatus.key == "loading"}
                                                onChange={(e, d) => this.setState({latitudeString: nullIfBlank(d.value)})}/>
                                    <Form.Input type="text"
                                                required
                                                label={this.props.t("agency.general.createEditStation.longitude")}
                                                placeholder={this.props.t("agency.general.createEditStation.longitude")}
                                                value={longitudeString || ""}
                                                error={this.getLongitudeStatus().key == "error"}
                                                disabled={geocodeStatus.key == "loading"}
                                                onChange={(e, d) => this.setState({longitudeString: nullIfBlank(d.value)})}/>
                                </Form.Group>
                            </Form>
                        </Grid.Column>
                    </Grid.Row>
                </Grid>
                {actionPanel}
            </div>
        );
    }

    render() {
        const {actionStatus} = this.state;
        const {viewStatus, onClose} = this.props;
        let primaryButtonText = null;
        if (viewStatus.key == "create") {
            primaryButtonText = this.props.t("general.create");
        } else if (viewStatus.key == "edit") {
            primaryButtonText = this.props.t("general.update");
        }
        return (
            <Modal open={viewStatus.key != "hidden"}
                   closeOnEscape={false}
                   onClose={onClose}>
                <Modal.Header>{this.props.t(`agency.general.createEditStation.${viewStatus.key == "create" ? "createTitle" : "editTitle"}`)}</Modal.Header>
                <Modal.Content>
                    {this.renderContent()}
                </Modal.Content>
                <Modal.Actions>
                    <Button content={this.props.t("general.cancel")}
                            disabled={actionStatus.key == "loading"}
                            onClick={onClose}/>
                    <Button primary
                            content={primaryButtonText}
                            loading={actionStatus.key == "loading"}
                            disabled={this.getSubmissionStatus().key == "incomplete"}
                            onClick={() => {
                                if (viewStatus.key == "create") {
                                    this.onCreate();
                                } else if (viewStatus.key == "edit") {
                                    this.onEdit();
                                }
                            }}/>
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(CreateEditStationModal, 'api', 'local', 'i18n');
