import {InitOptions as Options} from "i18next";
import XHR from 'i18next-xhr-backend';
import queryString from 'query-string';
import * as React from "react";
import {FormEvent, SyntheticEvent} from "react";
import {RouteComponentProps, StaticContext} from "react-router";
import {Redirect} from "react-router-dom";
import {Button, Divider, Form, Grid, Input, Message, Popup} from "semantic-ui-react";
import {ContactSupportModal} from './contactSupportModal';
import {ForgotPasswordModal} from './forgotPasswordModal';
import {ManageAuth as Auth, ApiResult} from '@bryxinc/lunch/models';
import BryxApi from '@bryxinc/lunch/utils/ManagementApi';
import * as colors from '@bryxinc/style/color';
import {SharedSupportUtils} from '@bryxinc/lunch/utils/functions';

import {withContext, WithTranslation, WithLocal, WithApi} from '@bryxinc/lunch/context';

interface LoginProps extends RouteComponentProps<{}, StaticContext, any>, WithTranslation, WithLocal, WithApi<BryxApi> {
}

interface LoginState {
    status: LoginStatus;
    overlayContent: "none" | "forgotPassword";
    email: string | null;
    password: string | null;
    initializing: boolean;
}

type AlertType = null | 'negative' | 'warning' | 'neutral';
type MessageContent = 'none' | 'passwordChanged' | 'forgotPasswordSent' | 'forceSignedOut' | 'manualSignedOut';
type LoadingType = 'manual' | 'auto';

type LoginStatus =
    { key: "ready", messageContent: MessageContent } |
    { key: "loading", type: LoadingType } |
    { key: "success", redirectLocation: string } |
    { key: "error", alertMessage: string };

interface BrandingProps extends WithTranslation, WithLocal, WithApi<BryxApi> {
}

const Branding = (props: BrandingProps) => (
    <div className="logoAndText">
        <img src={"/resources/assets/logo_white.png"} style={{width: "30px"}}/> <span
        className="siteName">{props.t("branding.siteName")}</span>
    </div>
);

export class Login extends React.Component<LoginProps, LoginState> {
    constructor(props: LoginProps, context: any) {
        super(props, context);

        this.state = {
            email: "",
            password: "",
            overlayContent: "none",
            initializing: true,
            status: this.getStatus(),
        };
    }

    private getStatus(): LoginStatus {
      const defaultStatus: LoginStatus = { key: 'ready', messageContent: 'none' };

      if (this.props.local.isSignedIn()) {
        return { key: 'success', redirectLocation: this.getRedirectLocation() };
      } else if (this.props.location.state) {
        switch (this.props.location.state.reason) {
          case 'forced':
            return { key: 'ready', messageContent: 'forceSignedOut' };
          case 'manual':
            return { key: 'ready', messageContent: 'manualSignedOut' };
          case 'passwordChanged':
            return { key: 'ready', messageContent: 'passwordChanged' };
          default:
            return defaultStatus;
        }
      } else {
        return defaultStatus;
      }
    }

    componentDidMount() {
        if (this.state.status.key != "success") {
            this.props.i18n
                .use(XHR)
                .init({
                    lng: this.props.local.get<string>("locale") ?? "en",
                    backend: {
                        loadPath: "/resources/locales/{{lng}}.json",
                    },
                    fallbackLng: "en",
                    interpolation: {escapeValue: false},
                } as Options, () => {
                    this.onI18nInit();
                });
        }
    }

    private onI18nInit() {
        if (this.props.location.search != null) {
            const params: { apiKey?: string } | null = queryString.parse(this.props.location.search);
            if (params != null && params["apiKey"] != null) {
                this.setState({
                    status: {key: "loading", type: "auto"},
                });

                this.props.api.signInWithApiKey<Auth>((params['apiKey'] ? params['apiKey'] : ''), result => {
                    if (result.success == true) {
                        this.props.local.initValuesFromAuthModel(result.value);
                        const redirectLocation = this.getRedirectLocation();
                        this.setState({
                            status: {
                                key: "success",
                                redirectLocation: redirectLocation,
                            },
                        });
                        this.props.local.logInfo("User successfully signed in with apiKey");
                    } else {
                        this.setState({
                            status: {
                                key: "error",
                                alertMessage: result.message,
                            },
                        });
                        this.props.local.logWarn(`User failed to sign in with apiKey: ${result.debugMessage}`);
                    }
                });
            }
        }
        this.setState({initializing: false});
    }

    private getRedirectLocation(): string {
      const defaultRedirect = '/agency';
      return (this.props.location.state && this.props.location.state.from) ?
        (this.props.location.state.from.pathname ?? defaultRedirect) : defaultRedirect;
    }

    private onSubmit(e: FormEvent<any>) {
        e.preventDefault();
        this.submitCredentials();
    }

    private submitCredentials(): void {
        this.setState({
            status: {key: "loading", type: "manual"},
        });

        const {email, password} = this.state;

        if (email == null || email == "" || password == null || password == "") {
            this.setState({
                status: {
                    key: "error",
                    alertMessage: this.props.t("login.blankEmailOrPassword"),
                },
            });
            return;
        }

        this.props.api.signIn<Auth>(email, password, null, (result: ApiResult<Auth>) => {
            if (result.success == true) {
                this.props.local.initValuesFromAuthModel(result.value);
                const redirectLocation = this.getRedirectLocation();
                this.setState({
                    status: {
                        key: "success",
                        redirectLocation: redirectLocation,
                    },
                });
                this.props.local.logInfo(`User successfully signed in, redirecting to ${redirectLocation}`);
            } else {
                this.setState({
                    status: {
                        key: "error",
                        alertMessage: result.message,
                    },
                });
                this.props.local.logWarn(`User failed to sign in: ${result.debugMessage}`);
            }
        });
    }

    private onChangeEmail(event: SyntheticEvent<HTMLInputElement>) {
        this.setState({
            email: event.currentTarget.value,
        });
    }

    private onChangePassword(event: SyntheticEvent<HTMLInputElement>) {
        this.setState({
            password: event.currentTarget.value,
        });
    }

    render() {
        if (this.state.status.key == "success") {
            return <Redirect to={this.state.status.redirectLocation}/>;
        }

        if (this.state.initializing || (this.state.status.key == "loading" && this.state.status.type == "auto")) {
            return <div style={{backgroundColor: colors.darkRed, height: "100%", width: "100%"}}/>;
        }

        let alertString = null;
        let alertType: AlertType = null;

        if (this.state.status.key == "error") {
            alertString = this.state.status.alertMessage;
            alertType = "negative";
        } else if (this.state.status.key == "ready") {
            switch (this.state.status.messageContent) {
                case 'manualSignedOut':
                    alertString = this.props.t('login.manualSignedOut');
                    alertType = 'neutral';
                    break;
                case "forceSignedOut":
                    alertString = this.props.t("login.forceSignedOut");
                    alertType = "negative";
                    break;
                case "passwordChanged":
                    alertString = this.props.t("login.passwordChanged");
                    alertType = "warning";
                    break;
            }
        }

        const alertMessage = alertString && alertType ? (
            <Message negative={alertType == "negative"}
                     warning={alertType == "warning"}
                     content={alertString}/>
        ) : null;

        const emailInputHtml = (
            <Input required
                   autoFocus
                   autoComplete={false}
                   autoCorrect={false}
                   autoCapitalize={false}
                   spellCheck={false}
                   type="username"
                   placeholder={this.props.t("login.email")}
                   value={this.state.email || ""}
                   disabled={this.state.status.key == "loading"}
                   onChange={this.onChangeEmail.bind(this)}/>
        );

        const pwInputHtml = (
            <Input required
                   type="password"
                   placeholder={this.props.t("login.password")}
                   value={this.state.password || ""}
                   disabled={this.state.status.key == "loading"}
                   onChange={this.onChangePassword.bind(this)}/>
        );

        const inputForm = (
            <Form onSubmit={this.onSubmit.bind(this)}>
                <Form.Field>{emailInputHtml}</Form.Field>
                <Form.Field>{pwInputHtml}</Form.Field>
                <Form.Field>
                    <Grid>
                        <Grid.Row columns={2} centered>
                            <Grid.Column style={{paddingRight: "0.5rem"}}>
                                <Popup position="left center"
                                       on="click"
                                       style={{textAlign: "center"}}
                                       content={this.props.t("login.forgotPasswordSuccess")}
                                       open={this.state.status.key == "ready" && this.state.status.messageContent == "forgotPasswordSent"}
                                       onClose={() => this.setState({
                                           status: {
                                               key: "ready",
                                               messageContent: "none",
                                           },
                                       })}
                                       trigger={
                                           <Button className="btn"
                                                   inverted
                                                   color="grey"
                                                   type="button"
                                                   style={{letterSpacing: "1px"}}
                                                   onClick={() => this.setState({
                                                       overlayContent: "forgotPassword",
                                                   })}>
                                               {this.props.t("login.forgotPassword").toUpperCase()}
                                           </Button>
                                       }/>
                            </Grid.Column>
                            <Grid.Column style={{paddingLeft: "0.5rem"}}>
                                <Button id="signinButton"
                                        className="btn"
                                        type="submit"
                                        loading={this.state.status.key == "loading"}
                                        style={{color: "#C61A1B", letterSpacing: "1px"}}>
                                    {this.props.t("login.signIn").toUpperCase()}
                                </Button>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </Form.Field>
            </Form>
        );

        return (
            <div>
                <Branding {...this.props}/>
                <div id="contactSupportDiv">
                    <a id="contactSupportLink"
                       className="whiteLink"
                       style={{textDecoration: "none"}}
                       onClick={() => SharedSupportUtils.onOpenSupport({from: this.state.email || undefined})}>
                        {this.props.t("contactSupport.headerSupport")}
                    </a>
                </div>
                <div className="login-modal">
                    <Grid verticalAlign="middle" style={{height: "100%"}}>
                        <Grid.Row centered>
                            <Grid.Column textAlign="left" id="loginForm">
                                <img id="signinLogo" src="/resources/assets/brand_logo.png"/>
                                {inputForm}
                                {alertMessage}
                                <Divider className="grayDivider" style={{margin: "2rem 0 1.5rem 0"}}/>
                                <a id="joinLink"
                                   className="whiteLink"
                                   target="_blank"
                                   style={{textDecoration: "none"}}
                                   href={this.props.api.joinUrl}>
                                    {this.props.t("login.joinLink")}
                                </a>
                            </Grid.Column>
                        </Grid.Row>
                    </Grid>
                </div>

                <ContactSupportModal {...this.props}/>

                <ForgotPasswordModal {...this.props}
                                     open={this.state.overlayContent == "forgotPassword"}
                                     email={this.state.email}
                                     onEmailChange={newEmail => this.setState({email: newEmail})}
                                     onCancel={() => this.setState({
                                         overlayContent: "none",
                                     })}
                                     onSuccess={() => this.setState({
                                         overlayContent: "none",
                                         status: {
                                             key: "ready",
                                             messageContent: "forgotPasswordSent",
                                         },
                                     })}/>
            </div>
        );
    }
}

export default withContext(Login, 'api', 'local', 'i18n');
