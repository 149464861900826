import * as L from "leaflet";
import {GeoJSON, LatLngBounds} from "leaflet";
import {Point as GeoJSONPoint} from "geojson";
import * as React from "react";
import {SyntheticEvent} from "react";
import Control from "react-leaflet-control";
import {
    Button,
    Dropdown,
    DropdownProps,
    Form,
    Header,
    Icon,
    Message,
    Popup,
    Table,
} from "semantic-ui-react";
import {BryxGeoJSONLayer} from "../../components/bryxGeoJSONLayer";
import {BryxMap} from "../../components/bryxMap";
import {
    Agency,
    AgencySettings,
    RespondingSetting,
    Station,
    ApiResult,
} from "@bryxinc/lunch/models";
import {AgencyProps} from "./agencyPage";
import {
    CreateEditStationModal,
    CreateEditStationModalViewStatus,
} from "./createEditStationModal";
import {ConfirmDeleteStationModal} from "./confirmDeleteStationModal";
import {EditBoundaryModal} from "./editBoundaryModal";

import {withContext} from "@bryxinc/lunch/context";

interface GeneralProps extends AgencyProps {
    activeAgency: Agency;
    onSettingsChanged: (newSettings: AgencySettings) => void;
    onReload: () => void;
}

interface GeneralState {
    centeredMap: boolean;
    mapBounds: LatLngBounds | undefined;
    newSettings: AgencySettings;
    updateSettingsStatus:
        | { key: "ready" }
        | { key: "loading" }
        | { key: "failed"; message: string };
    modalStatus:
        | { key: "none" }
        | { key: "editBoundary" }
        | { key: "addStation" }
        | { key: "editStation"; station: Station }
        | { key: "deleteStation"; station: Station };
}

export enum GeneralTabAction {
    addStation,
    addStationForBoardCreation,
}

export class GeneralTab extends React.Component<GeneralProps, GeneralState> {
    constructor(props: GeneralProps, context: any) {
        super(props, context);
        this.state = GeneralTab.getInitialState(props);
    }

    static getInitialState(props: GeneralProps): GeneralState {
        const activeAgency = props.activeAgency;
        const totalBounds =
            activeAgency.bufferedBoundary &&
            L.geoJSON(activeAgency.bufferedBoundary).getBounds();

        const action = GeneralTab.getAction(props);

        return {
            centeredMap: true,
            mapBounds: totalBounds || undefined,
            newSettings: activeAgency.settings.getCopy(),
            updateSettingsStatus: {key: "ready"},
            modalStatus:
                action == GeneralTabAction.addStation ||
                action == GeneralTabAction.addStationForBoardCreation
                    ? {key: "addStation"}
                    : {key: "none"},
        };
    }

    componentWillReceiveProps(
        nextProps: Readonly<GeneralProps>,
        nextContext: any,
    ) {
        if (
            nextProps.activeAgency.id != this.props.activeAgency.id ||
            nextProps.selectedAgency.id != this.props.selectedAgency.id
        ) {
            this.setState(GeneralTab.getInitialState(nextProps));
        }
    }

    private static getAction(props: GeneralProps): GeneralTabAction | null {
        return props.history.location.state != null
            ? (props.history.location.state as GeneralTabAction)
            : null;
    }

    private locateStation(location: GeoJSONPoint) {
        const stationBounds = GeoJSON.coordsToLatLng(
            location.coordinates as [number, number],
        ).toBounds(600);

        this.setState({
            centeredMap: false,
            mapBounds: stationBounds || undefined,
        });
    }

    private recenterMap() {
        const activeAgency = this.props.activeAgency;
        const totalBounds =
            activeAgency.bufferedBoundary &&
            L.geoJSON(activeAgency.bufferedBoundary).getBounds();

        this.setState({
            centeredMap: true,
            mapBounds: totalBounds || undefined,
        });
    }

    private updateUserRespondingSetting(
        event: SyntheticEvent<any>,
        data: DropdownProps,
    ) {
        this.setState((prevState: GeneralState, props: GeneralProps) => {
            prevState.newSettings.userResponding = data.value as RespondingSetting;
            return prevState;
        });
    }

    private updateApparatusRespondingSetting(
        event: SyntheticEvent<any>,
        data: DropdownProps,
    ) {
        this.setState((prevState: GeneralState, props: GeneralProps) => {
            prevState.newSettings.apparatusResponding =
                data.value as RespondingSetting;
            return prevState;
        });
    }

    private updateShowHydrantsSetting(
        event: SyntheticEvent<any>,
        data: DropdownProps,
    ) {
        this.setState((prevState: GeneralState, props: GeneralProps) => {
            prevState.newSettings.showHydrants = data.value == "on";
            return prevState;
        });
    }

    private updateRequirePasscodeSetting(
        event: SyntheticEvent<any>,
        data: DropdownProps,
    ) {
        this.setState((prevState) => {
            prevState.newSettings.requirePasscode = data.value == "on";
            return prevState;
        });
    }

    private onSaveSettings() {
        if (this.state.updateSettingsStatus.key == "loading") {
            return;
        }
        this.setState({updateSettingsStatus: {key: "loading"}});
        this.props.api.updateAgency(
            this.props.activeAgency.id,
            this.state.newSettings,
            (result: ApiResult<null>) => {
                if (result.success == true) {
                    this.setState({updateSettingsStatus: {key: "ready"}});
                    this.props.onSettingsChanged(this.state.newSettings.getCopy());
                } else {
                    this.props.local.logWarn(
                        `Failed to update agency: ${result.debugMessage || result.message}`,
                    );
                    this.setState({
                        updateSettingsStatus: {key: "failed", message: result.message},
                    });
                }
            },
        );
    }

    render() {
        const {activeAgency} = this.props;

        const stationMarkers = activeAgency.stations.map((station) => (
            <BryxGeoJSONLayer
                {...this.props}
                key={station.name}
                geojson={station.location}
                icon={BryxMap.stationIcon}
            />
        ));
        const agencyBorder = (
            <BryxGeoJSONLayer
                {...this.props}
                geojson={activeAgency.bufferedBoundary}
            />
        );

        const stationsData = activeAgency.stations.map((station) => {
            return (
                <Table.Row key={station.id}>
                    <Table.Cell>{station.name}</Table.Cell>
                    <Table.Cell
                        style={{
                            display: "flex",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        {station.address}
                        <Dropdown
                            pointing="right"
                            icon={{name: "ellipsis vertical", size: "large"}}
                        >
                            <Dropdown.Menu>
                                <Dropdown.Item
                                    icon="location arrow"
                                    text={this.props.t("agency.general.locateStation")}
                                    onClick={() => this.locateStation(station.location)}
                                />
                                <Dropdown.Item
                                    icon="pencil"
                                    text={this.props.t("general.edit")}
                                    onClick={() =>
                                        this.setState({
                                            modalStatus: {key: "editStation", station: station},
                                        })
                                    }
                                />
                                <Dropdown.Divider/>
                                <Dropdown.Item
                                    icon="x"
                                    text={this.props.t("general.delete")}
                                    onClick={() =>
                                        this.setState({
                                            modalStatus: {key: "deleteStation", station: station},
                                        })
                                    }
                                />
                            </Dropdown.Menu>
                        </Dropdown>
                    </Table.Cell>
                </Table.Row>
            );
        });

        const stationTable = (
            <Table striped style={{marginTop: "15px"}}>
                <Table.Header>
                    <Table.Row>
                        <Table.HeaderCell width={5}>
                            {this.props.t("agency.general.stationsNameLabel")}
                        </Table.HeaderCell>
                        <Table.HeaderCell width={11}>
                            {this.props.t("agency.general.stationsAddressLabel")}
                        </Table.HeaderCell>
                    </Table.Row>
                </Table.Header>
                <Table.Body>{stationsData}</Table.Body>
            </Table>
        );

        const updateSettingsStatus = this.state.updateSettingsStatus;
        const editSettingsDiv = !this.props.activeAgency.settings.equals(
            this.state.newSettings,
        ) ? (
            <div style={{display: "inline-block", marginTop: "20px"}}>
                <Button
                    negative
                    content={this.props.t("general.cancel")}
                    disabled={updateSettingsStatus.key == "loading"}
                    onClick={() => {
                        this.setState({
                            newSettings: this.props.activeAgency.settings.getCopy(),
                            updateSettingsStatus: {key: "ready"},
                        });
                    }}
                />
                <Button
                    primary
                    content={this.props.t("general.save")}
                    style={{marginLeft: "10px"}}
                    loading={updateSettingsStatus.key == "loading"}
                    onClick={this.onSaveSettings.bind(this)}
                />
            </div>
        ) : undefined;

        const respondingSettingsOptions = [
            {
                key: "off",
                value: "off",
                text: this.props.t("agency.respondingSettings.off"),
            },
            {
                key: "on",
                value: "on",
                text: this.props.t("agency.respondingSettings.on"),
            },
            {
                key: "forced",
                value: "forced",
                text: this.props.t("agency.respondingSettings.forced"),
            },
        ];

        const agencySettings = (
            <Form style={{marginTop: "15px"}}>
                <Form.Select
                    label={[
                        this.props.t("agency.general.userResponding.label"),
                        <Popup
                            key={1}
                            position="left center"
                            header={this.props.t("agency.general.userResponding.label")}
                            content={this.props.t(
                                "agency.general.userResponding.description",
                            )}
                            trigger={
                                <Icon name="help circle" style={{margin: "0 0 0 5px"}}/>
                            }
                        />,
                    ]}
                    options={respondingSettingsOptions}
                    value={this.state.newSettings.userResponding}
                    disabled={updateSettingsStatus.key == "loading"}
                    onChange={this.updateUserRespondingSetting.bind(this)}
                />
                <Form.Select
                    label={[
                        this.props.t("agency.general.apparatusResponding.label"),
                        <Popup
                            key={1}
                            position="left center"
                            header={this.props.t("agency.general.apparatusResponding.label")}
                            content={this.props.t(
                                "agency.general.apparatusResponding.description",
                            )}
                            trigger={
                                <Icon name="help circle" style={{margin: "0 0 0 5px"}}/>
                            }
                        />,
                    ]}
                    options={respondingSettingsOptions}
                    value={this.state.newSettings.apparatusResponding}
                    disabled={updateSettingsStatus.key == "loading"}
                    onChange={this.updateApparatusRespondingSetting.bind(this)}
                />
                <Form.Select
                    label={[
                        this.props.t("agency.general.showHydrants.label"),
                        <Popup
                            key={0}
                            position="left center"
                            header={this.props.t("agency.general.showHydrants.label")}
                            content={this.props.t("agency.general.showHydrants.description")}
                            trigger={
                                <Icon name="help circle" style={{margin: "0 0 0 5px"}}/>
                            }
                        />,
                    ]}
                    options={[
                        {
                            key: "off",
                            value: "off",
                            text: this.props.t("agency.agencySettings.off"),
                        },
                        {
                            key: "on",
                            value: "on",
                            text: this.props.t("agency.agencySettings.on"),
                        },
                    ]}
                    value={this.state.newSettings.showHydrants ? "on" : "off"}
                    disabled={updateSettingsStatus.key == "loading"}
                    onChange={this.updateShowHydrantsSetting.bind(this)}
                />
                <Form.Select
                    label={[
                        this.props.t("agency.general.requirePasscode.label"),
                        <Popup
                            key={0}
                            position="left center"
                            header={this.props.t("agency.general.requirePasscode.label")}
                            content={this.props.t(
                                "agency.general.requirePasscode.description",
                            )}
                            trigger={
                                <Icon name="help circle" style={{margin: "0 0 0 5px"}}/>
                            }
                        />,
                    ]}
                    options={[
                        {
                            key: "off",
                            value: "off",
                            text: this.props.t("agency.agencySettings.off"),
                        },
                        {
                            key: "on",
                            value: "on",
                            text: this.props.t("agency.agencySettings.on"),
                        },
                    ]}
                    value={this.state.newSettings.requirePasscode ? "on" : "off"}
                    disabled={updateSettingsStatus.key == "loading"}
                    onChange={this.updateRequirePasscodeSetting.bind(this)}
                />
            </Form>
        );

        const recenterControl =
            this.state.centeredMap == false ? (
                <Control position="topleft">
                    <div className="easy-button-container leaflet-bar">
                        <button
                            style={{cursor: "pointer"}}
                            onClick={this.recenterMap.bind(this)}
                            className="easy-button-button leaflet-bar-part leaflet-interactive"
                        >
                            <Icon name="crosshairs"/>
                        </button>
                    </div>
                </Control>
            ) : undefined;

        let createEditStationModalStatus: CreateEditStationModalViewStatus;
        if (this.state.modalStatus.key == "addStation") {
            createEditStationModalStatus = {key: "create"};
        } else if (this.state.modalStatus.key == "editStation") {
            createEditStationModalStatus = {
                key: "edit",
                station: this.state.modalStatus.station,
            };
        } else {
            createEditStationModalStatus = {key: "hidden"};
        }

        return (
            <div className="underHorizNavContent" style={{padding: "40px"}}>
                <BryxMap
                    {...this.props}
                    style={{height: "400px"}}
                    bounds={this.state.mapBounds}
                    scrollWheelZoom={false}
                >
                    {recenterControl}
                    {stationMarkers}
                    {agencyBorder}
                </BryxMap>
                <div style={{width: "100%", padding: "10px 0"}}>
                    <Button
                        primary
                        content={this.props.t("agency.general.editBoundary.buttonName")}
                        style={{float: "right"}}
                        onClick={() =>
                            this.setState({modalStatus: {key: "editBoundary"}})
                        }
                    />
                </div>
                <div
                    style={{display: "flex", flexDirection: "row", margin: "30px 0"}}
                >
                    <div style={{flex: 3}}>
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Header as="h2" style={{margin: 0}}>
                                {this.props.t("agency.general.stationsSectionLabel")}
                            </Header>
                            <Button
                                positive
                                onClick={() =>
                                    this.setState({modalStatus: {key: "addStation"}})
                                }
                                content={this.props.t("general.add")}
                                style={{margin: 0}}
                            />
                        </div>
                        {stationTable}
                    </div>
                    <div style={{padding: "0 0 30px 50px", flex: 1}}>
                        <Header as="h2" style={{margin: 0}}>
                            {this.props.t("agency.general.settingsSectionLabel")}
                        </Header>
                        {agencySettings}
                        {editSettingsDiv}
                        {updateSettingsStatus.key == "failed" ? (
                            <Message negative content={updateSettingsStatus.message}/>
                        ) : undefined}
                    </div>
                </div>
                <CreateEditStationModal
                    {...this.props}
                    viewStatus={createEditStationModalStatus}
                    selectedAgency={this.props.selectedAgency}
                    onClose={() => this.setState({modalStatus: {key: "none"}})}
                    onComplete={() => {
                        if (
                            GeneralTab.getAction(this.props) ==
                            GeneralTabAction.addStationForBoardCreation
                        ) {
                            this.props.history.replace("/station-boards/add");
                        } else {
                            this.setState(
                                {modalStatus: {key: "none"}},
                                this.props.onReload,
                            );
                        }
                    }}
                />
                <ConfirmDeleteStationModal
                    {...this.props}
                    viewStatus={
                        this.state.modalStatus.key == "deleteStation"
                            ? {
                                key: "shown",
                                agencyId: this.props.selectedAgency.id,
                                station: this.state.modalStatus.station,
                            }
                            : {key: "hidden"}
                    }
                    onConfirmRemove={() =>
                        this.setState({modalStatus: {key: "none"}}, this.props.onReload)
                    }
                    onClose={() => this.setState({modalStatus: {key: "none"}})}
                />
                <EditBoundaryModal
                    {...this.props}
                    viewStatus={
                        this.state.modalStatus.key == "editBoundary"
                            ? {
                                key: "shown",
                                agency: this.props.activeAgency,
                            }
                            : {key: "hidden"}
                    }
                    onClose={() => this.setState({modalStatus: {key: "none"}})}
                />
            </div>
        );
    }
}

export default withContext(GeneralTab, "api", "local", "i18n");
