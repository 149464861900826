import * as React from "react";
import {Button, Message, Modal} from "semantic-ui-react";
import {ResponseOption, ResponseOptionTypeEnum} from "@bryxinc/lunch/models";
import BryxApi from "@bryxinc/lunch/utils/ManagementApi";

import {RouteComponentProps} from "react-router";
import {
    withContext,
    WithTranslation,
    WithLocal,
    WithApi,
} from "@bryxinc/lunch/context";

interface DeleteResponseOptionModalProps
    extends RouteComponentProps<any>,
        WithTranslation,
        WithLocal,
        WithApi<BryxApi> {
    agencyId: string;

    onCancel(): void;

    onComplete(): void;

    viewStatus:
        | { key: "hidden" }
        | {
        key: "shown";
        responseOption: ResponseOption;
        responsesForType: number;
    };
}

interface DeleteResponseOptionModalState {
    status:
        | { key: "ready" }
        | { key: "loading" }
        | { key: "error"; message: string };
}

export class DeleteResponseOptionModal extends React.Component<DeleteResponseOptionModalProps,
    DeleteResponseOptionModalState> {
    constructor(props: DeleteResponseOptionModalProps, context: any) {
        super(props, context);
        this.state = {
            status: {key: "ready"},
        };
    }

    componentWillReceiveProps(nextProps: DeleteResponseOptionModalProps) {
        if (
            this.props.viewStatus.key == "hidden" &&
            nextProps.viewStatus.key == "shown"
        ) {
            this.setState({
                status: {key: "ready"},
            });
        }
    }

    private deleteResponseOption(responseId: string) {
        this.props.api.deleteResponseOption(
            this.props.agencyId,
            responseId,
            (result) => {
                if (result.success == true) {
                    this.props.local.logInfo(
                        `Successfully deleted response option: ${responseId}`,
                    );
                    this.props.onComplete();
                } else {
                    this.setState({
                        status: {key: "error", message: result.message},
                    });
                    this.props.local.logWarn(
                        `Failed to delete response option: ${result.debugMessage}`,
                    );
                }
            },
        );
    }

    render() {
        const {onCancel, viewStatus} = this.props;

        let explanation: string | null = null;
        let header: string | null = null;
        if (viewStatus.key == "shown") {
            if (viewStatus.responsesForType == 1) {
                header = this.props.t(
                    "agency.responseOptions.cannotDeleteModalHeader",
                    {replace: {responseOptionName: viewStatus.responseOption.text}},
                );
                explanation = this.props.t("agency.responseOptions.xLastOfType", {
                    replace: {
                        responseType:
                            ResponseOptionTypeEnum[viewStatus.responseOption.type],
                    },
                });
            } else if (!viewStatus.responseOption.canDelete) {
                header = this.props.t(
                    "agency.responseOptions.cannotDeleteModalHeader",
                    {replace: {responseOptionName: viewStatus.responseOption.text}},
                );
                explanation = this.props.t(
                    "agency.responseOptions.xBreaksCompleteness",
                    {
                        replace: {
                            responseType:
                                ResponseOptionTypeEnum[viewStatus.responseOption.type],
                        },
                    },
                );
            } else {
                header = this.props.t("agency.responseOptions.deleteModalHeader", {
                    replace: {responseOptionName: viewStatus.responseOption.text},
                });
                explanation = this.props.t("agency.responseOptions.deleteExplain");
            }
        }

        return (
            <Modal size="small" open={viewStatus.key == "shown"} onClose={onCancel}>
                <Modal.Header>{header}</Modal.Header>
                <Modal.Content>
                    {explanation}
                    {this.state.status.key == "error" ? (
                        <Message error style={{marginTop: "10px"}}>
                            {this.state.status.message}
                        </Message>
                    ) : undefined}
                </Modal.Content>
                <Modal.Actions>
                    <Button
                        content={this.props.t(
                            `general.${
                                viewStatus.key == "shown" && viewStatus.responseOption.canDelete
                                    ? "cancel"
                                    : "ok"
                            }`,
                        )}
                        disabled={this.state.status.key == "loading"}
                        onClick={onCancel}
                    />
                    {viewStatus.key == "shown" && viewStatus.responseOption.canDelete ? (
                        <Button
                            negative
                            content={this.props.t("general.delete")}
                            loading={this.state.status.key == "loading"}
                            onClick={() => {
                                this.setState({
                                    status: {key: "loading"},
                                });
                                this.deleteResponseOption(viewStatus.responseOption.id);
                            }}
                        />
                    ) : undefined}
                </Modal.Actions>
            </Modal>
        );
    }
}

export default withContext(DeleteResponseOptionModal, "api", "local", "i18n");
